//@import '~prismjs/plugins/toolbar/prism-toolbar.css';
//@import '~prismjs/themes/prism-tomorrow';
//@import './scss/sanity.scss';

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/spinners';

/* You can add global styles to this file, and also import other style files */
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$enable-smooth-scroll: false;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// fusv-disable
$grays: (
	'100': $gray-100,
	'200': $gray-200,
	'300': $gray-300,
	'400': $gray-400,
	'500': $gray-500,
	'600': $gray-600,
	'700': $gray-700,
	'800': $gray-800,
	'900': $gray-900,
);
// fusv-enable

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #8513ba;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$cream: #f7e9da;
$blush: #dfc2c3;
$navy: #333941;

// scss-docs-start colors-map
$colors: (
	'blue': $blue,
	'indigo': $indigo,
	'purple': $purple,
	'pink': $pink,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'teal': $teal,
	'cyan': $cyan,
	'white': $white,
	'gray': $gray-600,
	'gray-dark': $gray-800,
	'cream': $cream,
	'blush': $blush,
	'navy': $navy,
);
// scss-docs-end colors-map
// #F9644B //- Orange
// #3CC0C6 //- Teal
// #923C9E //- Purp
// #F9D438 //- Yellow
// #14121C //- Off black
$primary: #000;
$secondary: #ececec;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// scss-docs-start theme-colors-map
$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'dark': $dark,
	'white': #ececec,
);
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-900;
$color-contrast-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29'));

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));

// Spacing
//
// Control the styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// Body
//
// Settings for the `<body>` element.

$body-bg: #fafafa;
$body-color: $gray-900;
$body-text-align: null;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: 'Rules', 'Helvetica Neue', 'Arial';
$input-btn-font-size: 11px;
$input-btn-line-height: 11px;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.9rem;
$input-btn-padding-x-lg: 2.5rem;
$input-btn-font-size-lg: 11px;

$input-btn-border-width: $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: 2px;

$btn-font-weight: 500;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: var(--#{$prefix}link-color);
$btn-link-hover-color: var(--#{$prefix}link-hover-color);
$btn-link-disabled-color: $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$form-floating-height: add(3rem, $input-height-border);
$form-floating-line-height: 1;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 1rem !default;
$form-floating-input-padding-t: 1.625rem !default;
$form-floating-input-padding-b: 0.625rem !default;
$form-floating-label-opacity: 0.65 !default;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !default;
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out !default;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: null;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

$border-width: 1px;
$border-color: $gray-300;

$border-radius: 0px !important;
$border-radius-sm: 0px !important;
$border-radius-lg: 0px !important;

$rounded-pill: 50rem;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$component-active-color: $white;
$component-active-bg: $primary;

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Rules', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
// stylelint-enable value-keyword-case
$font-family-base: var(--bs-font-sans-serif);
$font-family-code: var(--bs-font-monospace);

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/list-group';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';
