@import './bs.scss';

.bticker-item {
	* {
		font-family: Times;
		font-size: 56px !important;
		font-style: normal;
		font-weight: 400;
		line-height: 56px;

		text-align: left;
	}
}
.ribbon {
	.innerHtml {
		margin-bottom: 0;
		white-space: nowrap;
		font-size: 12px;
		* {
			margin-bottom: 0;
			white-space: nowrap;
			font-size: 12px;
		}
	}
}

@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('/assets/AktivGrotesk-Regular.woff2') format('woff2'), url('/assets/AktivGrotesk-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	ascent-override: 100%;
}
@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('/assets/AktivGrotesk-Bold.woff2') format('woff2'), url('/assets/AktivGrotesk-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	ascent-override: 100%;
}
@font-face {
	font-family: 'Rules';
	src: url('/assets/Rules-Medium.woff2') format('woff2'), url('/assets/Rules-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	ascent-override: 100%;
}
@font-face {
	font-family: 'Rules';
	src: url('/assets/Rules-Regular.woff2') format('woff2'), url('/assets/Rules-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	ascent-override: 100%;
}
@font-face {
	font-family: 'Arges';
	src: url('/assets/Arges-NormalBlack.woff2') format('woff2'), url('/assets/Arges-NormalBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

h1 {
	font-family: 'Arges';
}
h4 {
	font-family: 'Times', 'Times New Roman', serif !important;
}
.times {
	&,
	* {
		font-family: 'Times', 'Times New Roman', serif !important;
	}
}
.aktiv {
	&,
	* {
		font-family: 'Aktiv Grotesk', 'Arial' !important;
	}
}
.rules {
	&,
	* {
		font-family: 'Rules' !important;
	}
}
.arges {
	&,
	* {
		font-family: 'Arges' !important;
	}
}
.fs-10 {
	font-size: 10px;
}
.fs-12 {
	font-size: 12px;
}
.fs-14 {
	font-size: 14px;
}
p {
	font-size: 12px;
}

hr {
	display: block;
	width: 100%;
}

.actionable,
.actionable svg {
	cursor: pointer;
}

.cursor {
	cursor: default;
}

.relative {
	position: relative;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track {
	width: 6px;
	background-color: transparent;
	// background-color: #f6f2ef;
}

.product-filter::-webkit-scrollbar,
.product-filter::-webkit-scrollbar-track {
	width: 0px !important;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
	background: #000;
	width: 6px;
	overflow: hidden;
}

// BUTTON STYLES //

a,
.actionable,
.actionable svg {
	cursor: pointer;
	text-decoration: none;
}
// this is broken
html body a:not(.no-special):not(.btn),
button.link {
	display: inline-block;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		height: 0.5px !important;
		width: 0;
		background-color: #000;
		bottom: 0;
		left: 0;
		transition: width cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.2s;
	}
	&.white:after {
		background-color: #fff !important;
	}
	&:hover:after {
		width: 100%;
		transition: width cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.4s;
	}
}

.btn {
	text-transform: uppercase;
}
@media screen and (max-width: 400px) {
	.btn-lg {
		padding: 0.7rem 1.4rem;
		font-size: 0.65rem;
		border-width: 2px;
	}
}
.z-1 {
	z-index: 1;
}
.z-0 {
	z-index: 0;
}

.btn.action {
	color: #313131;
	border-color: #ededed;
	border-width: 1px;
	border-radius: 20px;
	background-color: white;
	white-space: nowrap;
	padding: 12px 20px 10px 20px;
	font-family: 'Aktiv Grotesk';
	font-size: 10px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.05em;
	margin-right: 5px;
	display: flex;
	align-items: center;
	text-align: center;
	&.active {
		background: #313131 !important;
		color: #fff !important;
	}
}

@media screen and (max-width: 768px) {
	.instafeed-container {
		width: 50% !important;
		padding-top: 50% !important;
	}
}
